import React from "react"
import { Container, Badge } from "react-bootstrap"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaEnvelope } from "react-icons/fa"
import { FaPhoneAlt } from "react-icons/fa"
import { FaVideo } from "react-icons/fa"
import LanguageMenu from "./LanguageMenu"
import { useTranslation } from "react-i18next"

const Header = () => {
  const { t } = useTranslation()
  return (
    <header className="border-bottom">
      <Container className="d-flex justify-content-between my-3">
        <div className="info-box">
          <a
            href="https://www.allianz.de/service/kontaktformular/?ika-id=489&ika-channel=AZDE"
            className="mr-2 text-grey"
            target="_blank"
            rel="noreferrer"
            aria-label={t("header.linkToEmail")}
          >
            <Badge variant="grey" className="mr-2">
              <FaEnvelope />
            </Badge>
            <span className="align-middle d-none d-md-inline">
              chr.beyer@allianz.de
            </span>
          </a>
          <a
            href="tel:+49521986020"
            className="d-blockx text-grey"
            aria-label={t("header.linkToPhone")}
          >
            <Badge variant="grey" className="mr-2">
              <FaPhoneAlt />
            </Badge>
            <span className="align-middle d-none d-sm-inline">0521.986020</span>
          </a>
        </div>
        <div>
          <LanguageMenu />
          <span className="d-none d-lg-inline-block text-grey mr-2">
            <FaMapMarkerAlt /> Jöllenbeckerstr. 129, 33613 Bielefeld
          </span>
          <a
            href="https://wa.me/4952198620/?text=Herzlich Willkommen im WhatsApp Chat der Allianz Beyer OHG"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
            aria-label={t("header.linkToWhatsApp")}
          >
            <span
              className="position-relative"
              style={{
                top: -0.03 + "em",
              }}
            >
              <FaVideo />
            </span>
            <span className="pl-2 d-none d-sm-inline-block">
              {t("header.videoChat")}
            </span>
          </a>
        </div>
      </Container>
    </header>
  )
}

export default Header
