import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "react-bootstrap"

const LanguageMenu = props => {
  const { i18n } = useTranslation()
  const [values, setValues] = useState({
    language: "de",
  })
  function handleChange(event) {
    i18n.changeLanguage(event.target.value)
    setValues({
      language: event.target.value,
    })
  }

  return (
    <>
      <Button
        variant={values.language === "en" ? "outline-primary" : "link "}
        onClick={e => handleChange(e)}
        value={"en"}
        size="sm"
      >
        EN
      </Button>
      <Button
        className="mr-2 "
        onClick={e => handleChange(e)}
        value={"de"}
        variant={values.language === "de" ? "outline-primary" : "link "}
        size="sm"
      >
        DE
      </Button>
    </>
  )
}

export default LanguageMenu
