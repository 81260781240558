import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import FooterModals from "./FooterModals"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer>
      <Container className="my-auto">
        <Row>
          <Col className="d-flex justify-content-between py-3">
            <p className="my-auto">
              <a
                href="https://vertretung.allianz.de/chr.beyer/"
                className="d-block d-md-inline text-white"
              >
                Vertretung allianz.de
              </a>{" "}
              © {new Date().getFullYear()}
              {` `}
              <span>{t("footer.copyright")}</span>
            </p>
            <div className="text-right">
              <FooterModals />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
