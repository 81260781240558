import React from "react"
import { Link } from "gatsby"
import { FaGlobe } from "react-icons/fa"
import { Container } from "react-bootstrap"
import Logo from "./Logo"
import { useTranslation } from "react-i18next"

const CustomNavbar = ({ pageInfo, data }) => {
  const { t } = useTranslation()
  return (
    <>
      <Container className="d-flex justify-content-between my-3">
        <div>
          <Link
            to="/"
            aria-label={t("navBar.linkToHome")}
            className="inline-block mr-3 float-left"
          >
            <Logo />
          </Link>
          <span className="claim align-middle d-none d-lg-inline-block pr-3 text-grey">
            <strong>BYR</strong> {t("navBar.digitalAsset")} <br />{" "}
            {t("navBar.from")} <strong>Allianz Beyer OHG</strong>
          </span>
          <a
            href="https://vertretung.allianz.de/chr.beyer/"
            className="btn btn-primary d-none d-sm-inline-block"
            target="_blank"
            rel="noreferrer"
            aria-label={t("navBar.linkToAllianz")}
          >
            <span className="pr-2 align-top">
              <FaGlobe />
            </span>
            Allianz Beyer OHG {t("navBar.mainWebsite")}
          </a>
        </div>
        <div className="my-auto">
          <a
            href="https://cim.allianz.de/ui/login/de/allianz/start"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
            aria-label={t("navBar.linkToLogin")}
          >
            Login
          </a>
        </div>
      </Container>
    </>
  )
}

export default CustomNavbar
