import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const FooterModals = () => {
  const [ImpShow, setImpShow] = useState(false)
  const [DsShow, setDsShow] = useState(false)
  const { t } = useTranslation()

  return (
    <ul className="nav justify-content-end">
      <li className="nav-item">
        <button
          className="nav-link text-white btn btn-link"
          onClick={() => setImpShow(true)}
        >
          {t("footerModal.buttonImprint")}
        </button>
      </li>
      <li className="nav-item">
        <button
          className="nav-link text-white btn btn-link"
          onClick={() => setDsShow(true)}
        >
          {t("footerModal.buttonPrivacy")}
        </button>
      </li>
      <li className="nav-item">
        <a href="https://www.allianz.com/" className="nav-link text-white">
          Allianz.com
        </a>
      </li>
      <Modal
        size="lg"
        show={ImpShow}
        onHide={() => setImpShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Impressum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section id="one">
            <div className="inner">
              <header className="major">
                <h2>Allianz BEYER OHG</h2>
              </header>
              <address>
                Jöllenbecker Str. 129 <br />
                33613 Bielefeld
                <br />
                <br />
                Telefon: +49 521 986020
                <br />
                Telefax: +49 521 9860266
                <br />
                E-Mail:{" "}
                <a href="mailto:&#099;&#104;&#114;&#046;&#098;&#101;&#121;&#101;&#114;&#064;&#097;&#108;&#108;&#105;&#097;&#110;&#122;&#046;&#100;&#101;">
                  chr.beyer@allianz.de
                </a>
              </address>
            </div>
          </section>
          <section id="two">
            <div className="inner">
              <p>
                Eingetragener Agenturname im Handelsregister:
                <br />
                Beyer OHG
                <br />
                Handelsregisternummer: 16250
                <br />
                Handelsregister: Amtsgericht Bielefeld
                <br />
                Sitz: Bielefeld
                <br />
                Vertreten durch: Christian Beyer, Sebastian Beyer, Simon Beyer
              </p>
              <p>
                Simon Beyer: Beratung zu Versicherungen und deren Vermittlung
                als gebundener Vertreter (§ 34d Abs. 7 S. 1 Nr. 1 GewO)
                ausschließlich an die sowie für Rechnung und im Namen der
                Versicherungsunternehmen der Allianz. Vergütung durch
                Provisionen und Zusatzvergütungen aus Ausschreibungen (jeweils
                in der Versicherungsprämie enthalten).
              </p>
              <p>
                Sebastian Beyer: Beratung zu Versicherungen und deren
                Vermittlung als gebundener Vertreter (§ 34d Abs. 7 S. 1 Nr. 1
                GewO) ausschließlich an die sowie für Rechnung und im Namen der
                Versicherungsunternehmen der Allianz. Vergütung durch
                Provisionen und Zusatzvergütungen aus Ausschreibungen (jeweils
                in der Versicherungsprämie enthalten).
              </p>
              <p>
                Vermittlung von Investmentvermögen,
                Vermögensverwaltungsprodukten und sonstigen Finanzinstrumenten
                als vertraglich gebundener Vermittler (§ 2 Abs. 10 KWG)
                ausschließlich für Rechnung und unter der Haftung der Fondsdepot
                Bank GmbH
              </p>
              <h2>Zuständige Berufskammer:</h2>
              <address>
                Industrie- und Handelskammer Ostwestfalen zu Bielefeld
                <br />
                Elsa-Brändström-Straße 1-3
                <br />
                33602 Bielefeld
                <br />
                Telefon 0521/554-0
                <br />
                Telefax 0521/554-219
                <br />
                <br />
                <a href="http://www.bielefeld.ihk.de">
                  http://www.bielefeld.ihk.de
                </a>
                <br />
                <br />
              </address>
              <h2>Berufsbezeichnung:</h2>
              <p>
                Simon Beyer: Gebundener Versicherungsvertreter gemäß § 34d Abs.
                7 S.1 Nr.1 GewO; Bundesrepublik Deutschland Sebastian Beyer:
                Gebundener Versicherungsvertreter gemäß § 34d Abs. 7 S.1 Nr.1
                GewO; Bundesrepublik Deutschland
              </p>
              <p>
                Versicherungsvermittlerregister:
                <br />
                DIHK e.V., Breite Straße 29, 10178 Berlin, Tel. 0.180.600 58 50
                (Festnetzpreis 0,20 EUR/Anruf; Mobilfunkpreise maximal 0,60
                EUR/Anruf). Internet:
              </p>
              <a href="http://www.vermittlerregister.info">
                http://www.vermittlerregister.info
              </a>
              <p>
                Registernummer:
                <br />
                D-PADA-EZVZS-36: Simon Beyer
                <br />
                D-PBB3-XI7E9-18:Sebastian Beyer
              </p>
              <p>
                Die Agentur nimmt verpflichtend an folgenden außergerichtlichen
                Schlichtungsstellen als Streitbeilegungsstellen i.S.d.
                Verbraucherstreitbeilegungsgesetzes teil:
              </p>
              <h2>Schlichtungsstellen:</h2>
              <p>
                Versicherungsombudsmann e.V., Postfach 080632, 10006 Berlin,
                E-Mail:{" "}
                <a href="mailto:beschwerde@versicherungsombudsmann.de">
                  beschwerde@versicherungsombudsmann.de
                </a>
                . Ombudsmann Private Kranken- und Pflegeversicherung, Postfach
                060222, 10052 Berlin, E-Mail:{" "}
                <a href="mailto:ombudsmann@pkv.de">ombudsmann@pkv.de</a>.
                Ombudsmann Bundesverband deutscher Banken, Postfach 040307,
                10062 Berlin, E-Mail:{" "}
                <a href="mailto:bankenverband@bdb.de">bankenverband@bdb.de</a>
              </p>
              <p>
                Alternativ können Sie Ihre Beschwerde auch online über die
                Streitbeilegungsplattform der Europäischen Union (
                https://ec.europa.eu/consumers/odr/) einlegen.
              </p>
              <h2>Berufsrechtliche Regelungen:</h2>
              <p>
                § 34d Gewerbeordnung (GewO) §§ 59 – 68 Gesetz über den
                Versicherungsvertrag (VVG) Verordnung über die
                Versicherungsvermittlung und –beratung (VersVermV) Die
                berufsrechtlichen Regelungen können über die vom
                Bundesministerium der Justiz und für Verbraucherschutz und der
                von der juris GmbH betriebenen Homepage
                www.gesetze-im-internet.de eingesehen und abgerufen werden.
              </p>
              <p>
                Dieses Impressum bezieht sich auf sämtliche Telemediendienste
                der Agentur.
              </p>
            </div>
          </section>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={DsShow}
        onHide={() => setDsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            DATENSCHUTZHINWEISE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Versicherung, Vorsorge und Vermögensbildung sind Vertrauenssache.
            Die Persönlichkeitsrechte unserer Kunden und Partner zu
            respektieren, ist für uns ein wesentlicher Grundsatz. Das gilt
            selbstverständlich auch für den Umgang mit Ihren persönlichen Daten
            aus Ihrem Internetbesuch auf unseren Seiten. Im Rahmen der Nutzung
            dieser Webseiten können personenbezogene Daten von Ihnen durch den
            für die Datenverarbeitung Verantwortlichen (siehe Ziffer 1,
            nachfolgend auch mit „wir“ oder „uns“ bezeichnet) erhoben und
            verarbeitet und für die Dauer gespeichert werden, die zur Erfüllung
            der festgelegten bzw. gesetzlich vorgegebenen Zwecke erforderlich
            ist.
          </p>
          <h3>Verantwortlicher für die Datenverarbeitung</h3>
          <p>
            Verantwortlich für die Datenverarbeitung auf dieser Webseite ist die
            Allianz Vertretung:
          </p>
          <address>
            <strong>BEYER OHG</strong> <br /> Jöllenbecker Str.129, 33613
            Bielefeld
            <br /> Telefon: 0521 986020
            <br />
            Telefax: 0521 9860266
            <br />
            <a href="maillto:&#099;&#104;&#114;&#046;&#098;&#101;&#121;&#101;&#114;&#064;&#097;&#108;&#108;&#105;&#097;&#110;&#122;&#046;&#100;&#101;">
              E-Mail: chr.beyer@allianz.de
            </a>
          </address>
          <p>
            Einen gegebenenfalls bei uns benannten Datenschutzbeauftragten
            erreichen Sie ebenfalls unter diesen hinterlegten Kontaktdaten.
          </p>
          <p>
            Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung
            Beim Besuch der Webseiten Sie können sich auf unseren Webseiten
            bewegen, ohne Ihre Identität preisgeben zu müssen. Wir erfahren nur
            den Namen Ihres Internet Service Providers, die IP-Adresse des
            Anschlusses, den Sie nutzen, die Webseite, von der aus Sie uns
            besuchen und die Webseiten, die Sie bei uns besuchen. Diese
            Informationen werden zu statistischen Zwecken ausgewertet, ohne dass
            wir auf Ihre Identität schließen können.
          </p>
          <p>
            Beim Besuch unserer Webseite setzen wir Cookies ein. Nähere
            Informationen dazu finden Sie unter Punkt 4. dieser
            Datenschutzhinweise.
          </p>
          <h3>Bei der Nutzung eines Kontaktformulars</h3>
          <p>
            Sofern Sie den Link zum Senden einer E-Mail an uns oder zur
            Vereinbarung eines Termins mit uns klicken, werden Sie auf ein auf
            der Webseite der Allianz Deutschland AG hinterlegtes Kontaktformular
            weitergeleitet. Die dort von Ihnen eingegebenen Daten (z. B. Name,
            Anschrift, E-Mail-Adresse, Telefonnummer, Inhalt Ihrer Anfrage,
            bester Zeitpunkt für eine Kontaktaufnahme) werden an uns
            weitergeleitet und nach der Bearbeitung Ihres Anliegens gelöscht.
          </p>
          <p>
            Sollte die Vereinbarung eines Termins über den externen Anbieter
            bookingtime GmbH erfolgen, werden Sie darauf aufmerksam gemacht. Für
            die Erhebung der Daten gelten in diesem Fall die
            Datenschutzbestimmungen der bookingtime GmbH, die Sie bei der
            Terminbuchung einsehen können.
          </p>
          <h3>Bei einer telefonischen Kontaktaufnahme</h3>
          <p>
            Rufen Sie uns unter der auf der Webseite genannten Telefonnummer an
            und ist unser Büro nicht besetzt, wird Ihr Anruf ggf. an die die KVM
            ServicePlus Kunden- und Vertriebsmanagement GmbH (ein
            Tochterunternehmen der Allianz Deutschland AG) umgeleitet, die uns
            bei vertriebs- und kundennahen Serviceleistungen sowie beim
            Telefonservice unterstützt.
          </p>
          <h3>Einbindung externer Dienste</h3>
          <h4>Verwendung von Google Maps</h4>
          <p>
            Diese Webseite verwendet Google Maps zur Darstellung von Karten.
            Google Maps wird betrieben von Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Ireland.
          </p>
          <p>
            Durch die Nutzung dieser Webseite erklären Sie sich mit der
            Erfassung, Bearbeitung sowie der Nutzung der automatisch erhobenen
            sowie der von Ihnen eingegeben Daten durch Google, einer seiner
            Vertreter, oder Drittanbieter einverstanden.
          </p>
          <p>
            Die Nutzungsbedingungen für Google Maps finden Sie unter
            <a href="https://www.google.com/intl/de_de/help/terms_maps/">
              {" "}
              Nutzungsbedingungen für Google Maps
            </a>{" "}
            . Ausführliche Details finden Sie in der Datenschutzerklärung von
            Google:{" "}
            <a href="https://policies.google.com/privacy?hl=de&gl=de">
              Datenschutzerklärung
            </a>
          </p>
          <h4>YouTube</h4>
          <p>
            Sollte diese Webseite Videos von YouTube einbinden, handelt es sich
            bei den eingebetteten Videos um einen Service der Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Ireland. Sobald Sie
            eine mit einem eingebetteten Video ausgestattete Seite unseres
            Internetauftritts aufrufen, wird eine Verbindung zu den Servern von
            YouTube aufgebaut und diesen mitgeteilt, auf welcher Webseite Sie
            sich aktuell befinden. Daneben kann es zu einer Verbindungsaufnahme
            mit dem Google DoubleClick Netzwerk kommen. Wenn Sie das Video
            starten, könnte dies weitere Datenverarbeitungsvorgänge auslösen.
            Darauf haben wir keinen Einfluss. Weitere Informationen über den
            Datenschutz bei YouTube finden Sie in der Datenschutzerklärung von
            Google:{" "}
            <a href="https://policies.google.com/privacy">
              Datenschutzerklärung
            </a>
          </p>
          <h3>Einsatz von Cookies</h3>
          <p>
            Wir setzen auf unseren Webseiten Cookies ein. Cookies sind kleine
            Dateien, die auf Ihrem Endgerät abgelegt werden. Hierbei
            unterscheidet man zwischen sogenannten Session-Cookies, die nach dem
            Schließen Ihres Browsers wieder gelöscht werden, und den temporären
            und permanenten Cookies, die für einen längeren Zeitraum gespeichert
            werden.
          </p>
          <p>
            Die von uns genutzten Cookies enthalten keine Daten, die Sie
            persönlich identifizieren, wie z.B. Name, Telefonnummer, Post- und
            E-Mail-Adresse, Bankbeziehung oder Kreditkarteninformationen. Die
            von uns genutzten Cookies dienen beispielsweise dazu, Ihnen bei
            Ihrem nächsten Webseitenbesuch, die von Ihnen ausgewählte Allianz
            Agentur wieder zuzuordnen.
          </p>
          <p>
            Beim Ihrem ersten Besuch der Webseite zeigen wir einen Hinweis, dass
            wir Cookies nutzen. Setzen Sie den Besuch der Webseite fort,
            erklären Sie sich mit dem Einsatz von Cookies einverstanden. Für das
            Setzen von Cookies besteht für uns ein berechtigtes Interesse im
            Sinne von Art. 6 Abs. 1 f) der EU-Datenschutzgrundverordnung. Ein
            berechtigtes Interesse ergibt sich für Analyse- und
            Auswertungszwecke aus dem Ziel der Verbesserung der
            Webseitenerfahrung für den Benutzer. Daneben verfolgen wir mit dem
            Einsatz von Cookies Marketinginteressen.
          </p>
          <p>
            Falls Sie nicht möchten, dass Cookies auf Ihrem Rechner gespeichert
            werden, bitten wir Sie die entsprechende Option in den
            Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
            Cookies können in den Systemeinstellungen des Browsers gelöscht
            werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen
            dieser Webseite führen.
          </p>
          <p>
            Weitere Informationen zum Umgang mit Cookies finden Sie unter
            <a href="https://www.allianz.de/datenschutz/cookies/">
              https://www.allianz.de/datenschutz/cookies/
            </a>
          </p>
          <p>
            Dem Einsatz von Cookies unserer Partner können Sie unter den
            folgenden Links widersprechen:
          </p>
          <ul>
            <li>
              <a href="https://www.google.com/maps">Google Maps</a>
            </li>
          </ul>
          <h3>Links von Dritten</h3>
          <p>
            Unter Umständen bieten wir Links von Dritten an. Obwohl wir
            sorgfältig die angebotenen Links prüfen, übernehmen wir keine
            Verantwortung für den Umgang mit Ihren Daten auf diesen Webseiten.
            Informieren Sie sich bitte im Voraus über den Umgang mit
            personenbezogenen Daten durch diese Dritten.
          </p>
          <h3>Nutzung von Social-Media-Plattformen</h3>
          <p>
            Wir unterhalten Profile und Auftritte auf verschiedenen
            Social-Media-Plattformen (z. B. Facebook, Instagram). Wir wollen
            über diese Kanäle über die Produkte und Services der Allianz
            informieren und Interessenten und unsere Kunden bei Fragen und/oder
            Feedback rund um die Produkte und Services der Allianz unterstützen.
          </p>
          <p>
            Sollten wir zur Klärung Ihres Anliegens personenbezogene Daten von
            Ihnen benötigen, teilen wir Ihnen mit, welche Daten benötigt werden
            und welcher der geeignete, sichere Weg zur Übermittlung dieser Daten
            ist. Bitte beachten Sie, dass Sie sich mit der Nutzung der
            Social-Media-Plattformen den Nutzungs- und Datenschutzbedingungen
            des jeweiligen Anbieters unterwerfen.
          </p>
          <h3>Betrieb von Facebook-Fanpages</h3>
          <p>
            Sollten wir einen Facebook Auftritt betreiben, geschieht dies, um
            über die Produkte und Services der Allianz zu informieren und
            Interessenten und Allianz Kunden bei Fragen und/oder Feedback rund
            um die Produkte und Services der Allianz zu unterstützen.
          </p>
          <p>
            Sollten wir zur Klärung Ihres Anliegens Daten von Ihnen benötigen,
            teilen wir Ihnen mit, welche Daten benötigt werden und welcher der
            geeignete, sichere Weg zur Übermittlung dieser Daten ist. Wir werden
            nur die zur Klärung Ihres Anliegens erforderlichen Daten erheben.
          </p>
          <p>
            Bitte posten Sie keine personenbezogenen Daten in die
            Kommentarfunktion oder auf die Pinnwand unseres Facebook-Auftrittes
            und schicken Sie uns keine personenbezogenen Daten via privater
            Nachricht. Grundsätzlich löschen wir Ihre Daten, sobald sie für die
            oben genannten Zwecke nicht mehr erforderlich sind. Bei
            Gewinnspielaktionen gelten die Datenschutzhinweise der jeweiligen
            Aktion.
          </p>
          <p>
            Des Weiteren informieren wir Sie, dass durch die Nutzung von
            Facebook auch eine Datenverarbeitung bei Facebook stattfindet, auf
            die wir keinen Einfluss haben. Nähere Informationen dazu finden sie
            auf:{" "}
            <a href="https://www.facebook.com/privacy/explanation">
              https://www.facebook.com/privacy/explanation
            </a>
          </p>
          <h3>Betroffenenrechte</h3>
          <p>
            Sie können beim Verantwortlichen Auskunft über die zu Ihrer Person
            gespeicherten Daten verlangen. Darüber hinaus können Sie unter
            bestimmten Voraussetzungen die Berichtigung oder die Löschung Ihrer
            Daten verlangen. Ihnen kann weiterhin ein Recht auf Einschränkung
            der Verarbeitung Ihrer Daten sowie ein Recht auf Herausgabe der von
            Ihnen bereitgestellten Daten in einem strukturierten, gängigen und
            maschinenlesbaren Format zustehen.
          </p>
          <p>
            Daneben haben Sie die Möglichkeit, sich an eine
            Datenschutzaufsichtsbehörde zu wenden.
          </p>
          <h3>Widerspruchsrecht</h3>
          <p>
            Sie können einer Verarbeitung Ihrer Daten zu Zwecken der
            Direktwerbung widersprechen. Verarbeiten wir Ihre Daten zur Wahrung
            berechtigter Interessen, können Sie dieser Verarbeitung aus Gründen,
            die sich aus Ihrer besonderen Situation ergeben, widersprechen.
          </p>
        </Modal.Body>
      </Modal>
    </ul>
  )
}

export default FooterModals
