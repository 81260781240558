import React from "react"
import { Jumbotron, Col, Row, Container } from "react-bootstrap"
import { FaMapMarkedAlt } from "react-icons/fa"
import { useTranslation } from "react-i18next"

const MyJumbotron = () => {
  const { t } = useTranslation()
  return (
    <Jumbotron fluid>
      <Container>
        <Row className="align-items-center">
          <Col sm={10} className="">
            <h1 className="bg-white text-primary rounded">
              {t("jumbotron.title")}
            </h1>
            <a
              href="https://www.allianz.de/service/kontaktformular/?ika-id=489#agentur=chr.beyer"
              className="btn btn-primary btn-lg mt-3"
            >
              {t("jumbotron.contact")}
            </a>
          </Col>
        </Row>
      </Container>
      <a
        href="https://www.google.com/maps/uv?pb=!1s0x47ba3d4350948ac3%3A0xfba84ccf1a3563c!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPl742q6xIstNpAMEgocX9qAuFjQFIgPbeGk6aW%3Dw480-h320-k-no!5sbeyer%20und%20partner%20ohg%20bielefeld%20-%20Google%20Suche!15sCgIgARICCAI&imagekey=!1e10!2sAF1QipPcD1ZdNCeg0SsAK6Z5COTBrw43nokssxaYhTH-&hl=de&sa=X&ved=2ahUKEwis3Mncq8frAhWCy6QKHc4_C5gQoiowCnoECBQQBg"
        className="google360"
        target="_blank"
        rel="noreferrer"
        aria-label={t("jumbotron.linkToGoogle360")}
      >
        <FaMapMarkedAlt />
      </a>
    </Jumbotron>
  )
}
export default MyJumbotron
