/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Header from "./Header"
import Navbar from "./NavBar"
import Footer from "./Footer"
import MyJumbotron from "./MyJumbotron"
import { withTrans } from "./i18n/withTrans"
import BackgroundSection from "./BackgroundSection"

const Layout = ({ children, pageInfo, t, i18n }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Row noGutters className="justify-content-center">
            <Col>
              <Header />
            </Col>
          </Row>
          <Navbar pageInfo={pageInfo} />

          <BackgroundSection className="jumbotron-container">
            <MyJumbotron />
          </BackgroundSection>
          <Row noGutters>
            <Col>
              <Container fluid>
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <Footer />
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default withTrans(Layout)
