import React from "react"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 139) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Img
          fixed={data.placeholderImage.childImageSharp.fixed}
          className="logo"
        />
      </>
    )}
  />
)

export default Logo
